import { GalleryImageType } from '@/common/type';

export const sortShuffle = (
  array: Array<GalleryImageType>,
): Array<GalleryImageType> => {
  array.sort(() => Math.random() - 0.5);
  return array;
};

export const sliceWithNumber = (array: Array<any> = [], number: number) => {
  const resultArray: Array<any> = [];
  let start = 0;

  while (start <= array.length) {
    resultArray.push(array.slice(start, start + number));
    start += number;
  }
  //   while (array.length) {

  //   }
  return resultArray;
};

export const getRestOfLocaleList = (
  localeList: Array<string>,
  locale: string,
): Array<string> => {
  const idx = localeList.findIndex((item) => item === locale);
  if (idx > -1) localeList.splice(idx, 1);
  return localeList;
};
