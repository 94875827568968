import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

/**
 * Next.js hydration 에러로 인한 트러블 슈팅용 훅
 * https://fourwingsy.medium.com/next-js-hydration-%EC%8A%A4%ED%83%80%EC%9D%BC-%EC%9D%B4%EC%8A%88-%ED%94%BC%ED%95%B4%EA%B0%80%EA%B8%B0-988ce0d939e7
 */
export const useIsDesktop = (): boolean | undefined => {
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>(undefined);
  const desktop = useMediaQuery({ query: '(min-width: 1281px)' });

  useEffect(() => {
    setIsDesktop(desktop);
  }, [desktop]);

  return isDesktop;
};

export const useIsDesktopOrTablet = (): boolean => {
  const [isDesktopOrTablet, setIsDesktopOrTablet] = useState(false);
  const desktopOrTablet = useMediaQuery({ query: '(min-width: 700px)' });

  useEffect(() => {
    setIsDesktopOrTablet(desktopOrTablet);
  }, [desktopOrTablet]);

  return isDesktopOrTablet;
};

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: '(max-width: 700px)' });

  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);

  return isMobile;
};
