import styled from 'styled-components';
import { NavbarLink } from '../Navbar/style';

export const SideBarContainer = styled.div<{ isOpen: boolean }>`
  width: 35rem;
  height: 100vh;
  background: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
  transition: all 0.5s ease;
  background: white;
  /* border-left: 60px solid transparent; */
  /* border-bottom: 100vh solid white; */
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(40rem)'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PatternCanvas = styled.div`
  height: 100vh;
  position: absolute;
  left: 2rem;
  opacity: 0.3;
`;

export const LinkList = styled.ul`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  gap: 3rem;
`;

export const LinkListItem = styled.li`
  padding: 1rem;
`;

export const SidebarLink = styled(NavbarLink)`
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
`;

export const SidebarAnchor = styled.a`
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  text-decoration: none;
  position: relative;
  padding: 0.3rem 1rem;
  display: flex;
  width: auto;
  color: ${({ theme }) => theme.textColor.disabledColor};
  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0.25rem;
    background: black;
    transition: all 0.2s ease-out;
  }
  :hover::after {
    width: 80%;
  }
  :hover {
    color: black;
  }
  :visited {
    color: ${({ theme }) => theme.textColor.disabledColor};
  }
`;
