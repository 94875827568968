import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import React from 'react';
import * as S from './style';

const Footer = () => {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <S.FooterContainer>
      <S.LogoCanvas>
        <Image
          src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/clovergames_logo_white.webp`}
          alt="Logo of Clover Games Corporation"
          fill
        />
      </S.LogoCanvas>
      <S.CopyrightContainer>
        <S.CopyrightText>
          COPYRIGHT ©2020 CLOVER GAMES CORPORATION. ALL RIGHTS RESERVED.
        </S.CopyrightText>
        <S.CopyrightText>
          LORD OF HEROES and CLOVER GAMES are registered trademarks, trademarks
          or service marks of CLOVER GAMES CORPORATION.
        </S.CopyrightText>
      </S.CopyrightContainer>
      <S.FooterContents>
        <S.FooterContent>
          <S.FooterContentText
            href={`https://cdn.clovergames.io/page/${router.locale}/privacypolicy.html`}
            target="_blank"
          >
            {t('footer.privacyPolicy')}
          </S.FooterContentText>
        </S.FooterContent>
        <S.Range />
        <S.FooterContent>
          <S.FooterContentText
            href={`https://cdn.clovergames.io/page/${router.locale}/tos.html`}
            target="_blank"
          >
            {t('footer.termsOfService')}
          </S.FooterContentText>
        </S.FooterContent>
      </S.FooterContents>
    </S.FooterContainer>
  );
};

export default Footer;
