import styled from 'styled-components';

export const UtilContainer = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const LogoCanvas = styled.div`
  margin: 0 auto;
  font-size: 2.5rem;
  width: 17rem;
  height: 75%;
  position: relative;
`;

export const HamburgerCanvas = styled.div`
  position: absolute;
  right: 0;
  font-size: 2.5rem;
  z-index: 1;
`;
