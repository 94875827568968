import Link from 'next/link';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  height: 30vw;
  max-height: 200px;
  min-height: 130px;
  background-color: black;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const LogoCanvas = styled.div`
  width: 18rem;
  height: 3.6rem;
  position: relative;
  ${({ theme }) => theme.tablet} {
    width: 25rem;
    height: 5rem;
  }
`;

export const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.tablet} {
    gap: 0.8rem;
  }
`;

export const CopyrightText = styled.p`
  color: #999999;
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: center;
  ${({ theme }) => theme.tablet} {
    font-size: 1rem;
  }
`;

export const FooterContents = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const Range = styled.div`
  border: 1px solid white;
  height: 0.8rem;
`;

export const FooterContent = styled.li``;

export const FooterContentText = styled(Link)`
  color: #999999;
  font-size: 0.8rem;
  padding: 0.5rem;
  :visited {
    color: #999999;
  }
  ${({ theme }) => theme.tablet} {
    font-size: 1rem;
  }
`;
