import { useIsDesktop } from '@/hooks/useIsDesktop';
import { useRouter } from 'next/router';
import * as S from './style';
import * as M from './mobileStyle';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import NavBarUtils from '@/components/molecules/NavBarUtils';
import Hamburger from '@/components/atoms/Hamburger';
import SideBar from '../SideBar';
import { useState } from 'react';
import Link from 'next/link';

const Navbar = () => {
  const { t } = useTranslation('common');
  const isDesktop = useIsDesktop();
  const { pathname } = useRouter();
  const [isHamburgerClicked, setIsHamburgerClicked] = useState<boolean>(false);
  const router = useRouter();

  const routeToMain = () => {
    if (router.pathname === '/') return;
    router.push('/');
  };

  return typeof isDesktop === 'undefined' ? null : (
    <S.NavbarContainer>
      {isDesktop && (
        <S.Logo onClick={routeToMain}>
          <Image
            src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/logo.svg`}
            alt="Lord of Heroes logo"
            fill
          />
        </S.Logo>
      )}
      <S.ContentsContainer>
        {isDesktop ? (
          <>
            <S.MenuContainer>
              <S.MenuItem>
                <S.NavbarLink href="/" selected={pathname === '/'}>
                  {t('navbar.main')}
                </S.NavbarLink>
              </S.MenuItem>
              <S.MenuItem>
                <S.NavbarLink
                  href="/character"
                  selected={pathname.startsWith('/character')}
                >
                  {t('navbar.characters')}
                </S.NavbarLink>
              </S.MenuItem>
              <S.MenuItem>
                <S.NavbarLink href="/story" selected={pathname === '/story'}>
                  {t('navbar.story')}
                </S.NavbarLink>
              </S.MenuItem>
              <S.MenuItem>
                <S.NavbarLink
                  href="/gallery"
                  selected={pathname === '/gallery'}
                >
                  {t('navbar.gallery')}
                </S.NavbarLink>
              </S.MenuItem>
              <S.MenuItem>
                <S.NavbarLink
                  href="/contest"
                  selected={pathname === '/contest'}
                >
                  {t('navbar.contest')}
                </S.NavbarLink>
              </S.MenuItem>
            </S.MenuContainer>
            <NavBarUtils />
          </>
        ) : (
          <>
            <M.UtilContainer>
              <NavBarUtils />
              {/* <S.LanguageButton>KO</S.LanguageButton>
              <S.UtilSvgCanvas>
                <Image src={SoundOff} alt="Background music button" fill />
              </S.UtilSvgCanvas> */}
            </M.UtilContainer>
            <M.LogoCanvas>
              <Link href="/">
                <Image
                  src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/logo_m.svg`}
                  alt="Lord of Heroes logo"
                  fill
                  sizes="50vw"
                />
              </Link>
            </M.LogoCanvas>
            <M.HamburgerCanvas
              onClick={() => setIsHamburgerClicked(!isHamburgerClicked)}
            >
              <Hamburger isActive={isHamburgerClicked} />
            </M.HamburgerCanvas>
            <SideBar
              isOpen={isHamburgerClicked}
              setIsOpen={setIsHamburgerClicked}
            />
          </>
        )}
      </S.ContentsContainer>
    </S.NavbarContainer>
  );
};

export default Navbar;
