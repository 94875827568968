import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Footer from '../organisms/Footer';
import Navbar from '../organisms/Navbar';

export const Main = styled(motion.main)`
  padding-top: 4.25rem;
  ${({ theme }) => theme.mobile} {
    padding-top: 6rem;
  }
`;

// eslint-disable-next-line react/prop-types
export const DefaultLayout = ({ children }) => {
  const { pathname } = useRouter();
  return (
    <>
      <Navbar />
      <Main
        key={pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </Main>
      <Footer />
    </>
  );
};

export default DefaultLayout;
