import Link from 'next/link';
import styled, { css } from 'styled-components';

export const UtilBox = styled.ul<{
  showSns: boolean;
  showUtils: boolean;
  isDesktop: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-columns: ${({ showSns, showUtils, isDesktop }) => {
    if (showSns && !showUtils) {
      return 'repeat(3, 1fr)';
    } else if (!showSns && showUtils) {
      if (isDesktop) return 'repeat(5, 1fr)';
      else return 'repeat(2, 1fr)';
    } else return 'repeat(5, 1fr)';
  }};
  grid-gap: 1rem;
  place-items: center;
  li:nth-child(n + 2):nth-child(-n + 5) {
    /* padding: 0.5rem; */
  }
  ${({ theme }) => theme.tablet} {
    grid-gap: 2.5rem;
  }
  ${({ theme }) => theme.mobile} {
    grid-gap: 1rem;
  }
`;

export const UtilList = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.tablet} {
    padding: 1rem;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

export const LanguageSelectBox = styled.ul`
  position: absolute;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  background-color: ${({ theme }) => theme.textColor.disabledColor};
  top: 100%;
  a {
    padding: 0.5rem;
  }
`;

export const PresentLanguage = styled.div`
  font-size: 1.5rem;
  ${({ theme }) => theme.tablet} {
    font-size: 2.5rem;
  }
`;

export const LanguageButton = styled(Link)<{ selected?: boolean }>`
  font-size: 1.5rem;
  color: ${({ selected }) => (selected ? 'black' : 'white')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :visited {
    color: ${({ selected }) => (selected ? 'black' : 'white')};
  }
  :hover {
    color: black;
  }
  ${({ theme }) => theme.tablet} {
    font-size: 2.5rem;
  }
`;

export const UtilSvgCanvas = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  ${({ theme }) => theme.tablet} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
