import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction } from 'react';
import * as S from './style';
import { useRouter } from 'next/router';
import Image from 'next/image';
import NavBarUtils from '@/components/molecules/NavBarUtils';

const SideBar = (props: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('common');
  const { pathname, locale } = useRouter();

  return (
    <S.SideBarContainer isOpen={props.isOpen}>
      <S.PatternCanvas>
        <Image
          src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/sidebar/pattern.svg`}
          alt="Sidebar pattern image"
          style={{ width: 'auto', height: '100vh' }}
          width={32}
          height={564}
        />
      </S.PatternCanvas>
      <S.LinkList>
        <S.LinkListItem>
          {/* <S.StyledLink href="/">{t('navbar.main')}</S.StyledLink> */}
          <S.SidebarLink
            href="/"
            selected={pathname === '/'}
            onClick={() => props.setIsOpen(false)}
          >
            {t('navbar.main')}
          </S.SidebarLink>
        </S.LinkListItem>
        <S.LinkListItem>
          <S.SidebarLink
            href="/character"
            selected={pathname === '/character'}
            onClick={() => props.setIsOpen(false)}
          >
            {t('navbar.characters')}
          </S.SidebarLink>
        </S.LinkListItem>
        <S.LinkListItem>
          <S.SidebarLink
            href="/story"
            selected={pathname === '/story'}
            onClick={() => props.setIsOpen(false)}
          >
            {t('navbar.story')}
          </S.SidebarLink>
        </S.LinkListItem>
        <S.LinkListItem>
          <S.SidebarLink
            href="/gallery"
            selected={pathname === '/gallery'}
            onClick={() => props.setIsOpen(false)}
          >
            {t('navbar.gallery')}
          </S.SidebarLink>
        </S.LinkListItem>
        <S.LinkListItem>
          <S.SidebarLink
            href="/contest"
            selected={pathname === '/contest'}
            onClick={() => props.setIsOpen(false)}
          >
            {t('navbar.contest')}
          </S.SidebarLink>
        </S.LinkListItem>
        <S.LinkListItem>
          <S.SidebarAnchor href={`https://blog-${locale}.lordofheroes.com/`}>
            {t('navbar.blog')}
          </S.SidebarAnchor>
        </S.LinkListItem>
        <NavBarUtils showUtils={false} showSns={true} />
      </S.LinkList>
    </S.SideBarContainer>
  );
};

export default SideBar;
