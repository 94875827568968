import * as S from './style';

const Hamburger = (props: { isActive: boolean }) => {
  return (
    <>
      <S.HamburgerButtonContainer>
        <S.FirstBar isActive={props.isActive} />
        <S.SecondBar isActive={props.isActive} />
        <S.ThirdBar isActive={props.isActive} />
      </S.HamburgerButtonContainer>
    </>
  );
};

export default Hamburger;
