import styled, { css } from 'styled-components';

export const HamburgerButtonContainer = styled.div`
  width: 4rem;
  height: 4rem;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding: 10% 0;
  cursor: pointer;
`;

export const FirstBar = styled.div<{ isActive: boolean }>`
  background-color: black;
  border-radius: 1rem;
  width: 100%;
  height: 50%;
  margin: auto 0;
  transition: all 0.5s ease;
  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(45deg);
      translate: 0 1.1rem;
    `}
`;

export const SecondBar = styled.div<{ isActive: boolean }>`
  background-color: black;
  border-radius: 1rem;
  width: 100%;
  height: 50%;
  margin: auto 0;
  transition: all 0.5s ease;
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 0;
    `}
`;

export const ThirdBar = styled.div<{ isActive: boolean }>`
  background-color: black;
  border-radius: 1rem;
  width: 100%;
  height: 50%;
  margin: auto 0;
  transition: all 0.5s ease;
  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(-45deg);
      translate: 0 -1.1rem;
    `}
`;
