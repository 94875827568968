import { useEffect, useState } from 'react';

export const useAudio = ({ loop }: { loop: boolean }) => {
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined);
  const [playing, setPlaying] = useState<boolean>(false);

  // const clicked = () => setPlaying(!playing);
  const clicked = (): void => {
    if (loop) {
      setPlaying(!playing);
    } else {
      if (playing) return;
      else setPlaying(true);
    }
  };

  useEffect(() => {
    // console.log(audio);
    // audio && audio.play();
    if (audio) {
      setPlaying(false);
      audio.addEventListener('ended', () =>
        loop ? audio.play() : setPlaying(false),
      );
    }
    return () => {
      audio && audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  // 꺼져있으면 play 켜져있으면 pause
  useEffect(() => {
    if (audio) {
      playing ? audio.play() : audio.pause();
    }
  }, [playing]);

  return { playing, setPlaying, clicked, setAudio };
};
