import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import * as S from './style';
import { useIsDesktop } from '@/hooks/useIsDesktop';
import { useRouter } from 'next/router';
import { getRestOfLocaleList } from '@/utils/array';
import { useAudio } from '@/hooks/useAudio';
import { useTranslation } from 'next-i18next';

export interface NavBarUtilsProps {
  showUtils?: boolean;
  showSns?: boolean;
}

const NavBarUtils = (props: NavBarUtilsProps) => {
  const { showUtils = true, showSns = false } = props;
  const isDesktop = useIsDesktop();
  // const [isSoundHover, setIsSoundHover] = useState<boolean>(false);
  const [isKakaoHover, setIsKakaoHover] = useState<boolean>(false);
  const [isFacebookHover, setIsFacebookHover] = useState<boolean>(false);
  const [isYoutubeHover, setIsYoutubeHover] = useState<boolean>(false);
  const [isLangSelectOpen, setIsLangSelectOpen] = useState<boolean>(false);
  const { setAudio, clicked, playing } = useAudio({ loop: true });
  const { t } = useTranslation('common');
  const router = useRouter();
  const localeList = getRestOfLocaleList(
    router.locales as string[],
    router.locale as string,
  );

  useEffect(() => {
    setAudio(
      new Audio(
        `${process.env.NEXT_PUBLIC_RESOURCES_URL}/audios/home/bgm/remember-the-days.mp3`,
      ),
    );
  }, []);

  return (
    <S.UtilBox showSns={showSns} showUtils={showUtils} isDesktop={isDesktop}>
      {showUtils && (
        <>
          {router.locale === 'en' && isDesktop && <li />}
          <S.UtilList>
            <S.Dropdown
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsLangSelectOpen(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsLangSelectOpen(false);
              }}
            >
              <S.PresentLanguage>
                {router.locale?.toUpperCase()}
              </S.PresentLanguage>
              {isLangSelectOpen && (
                <S.LanguageSelectBox>
                  <S.LanguageButton
                    href={`${router.asPath}`}
                    locale={localeList[0]}
                    onClick={() => setIsLangSelectOpen(false)}
                  >
                    {localeList[0].toUpperCase()}
                  </S.LanguageButton>
                  <S.LanguageButton
                    href={`${router.asPath}`}
                    locale={localeList[1]}
                    onClick={() => setIsLangSelectOpen(false)}
                  >
                    {localeList[1].toUpperCase()}
                  </S.LanguageButton>
                </S.LanguageSelectBox>
              )}
            </S.Dropdown>
          </S.UtilList>
          <S.UtilList
            onClick={() => {
              clicked();
            }}
          >
            <S.UtilSvgCanvas>
              <Image
                src={
                  playing
                    ? `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/sound_on.svg`
                    : `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/sound_off.svg`
                }
                alt="Background music button"
                fill
                priority
              />
            </S.UtilSvgCanvas>
          </S.UtilList>
        </>
      )}
      {(isDesktop || showSns) && (
        <>
          <S.UtilList
            onMouseEnter={() => setIsYoutubeHover(true)}
            onMouseLeave={() => setIsYoutubeHover(false)}
          >
            <S.StyledLink href={t('navbar.youtubeLink')} target="_blank">
              <S.UtilSvgCanvas>
                <Image
                  src={
                    isYoutubeHover
                      ? `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/youtube_on.svg`
                      : `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/youtube_off.svg`
                  }
                  alt="Youtube link"
                  fill
                  priority
                />
              </S.UtilSvgCanvas>
            </S.StyledLink>
          </S.UtilList>
          {router.locale !== 'en' && (
            <S.UtilList
              onMouseEnter={() => setIsKakaoHover(true)}
              onMouseLeave={() => setIsKakaoHover(false)}
            >
              {router.locale === 'ko' ? (
                <S.StyledLink
                  href={'https://pf.kakao.com/_YXZqxb'}
                  target="_blank"
                >
                  <S.UtilSvgCanvas>
                    <Image
                      src={
                        isKakaoHover
                          ? `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/kakao_on.svg`
                          : `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/kakao_off.svg`
                      }
                      alt="Kakao link"
                      fill
                      priority
                    />
                  </S.UtilSvgCanvas>
                </S.StyledLink>
              ) : (
                <S.StyledLink
                  href={'https://twitter.com/lordofheroes_jp'}
                  target="_blank"
                >
                  <S.UtilSvgCanvas>
                    <Image
                      src={
                        isKakaoHover
                          ? `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/twitter_on.svg`
                          : `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/twitter_off.svg`
                      }
                      alt="Kakao link"
                      fill
                      priority
                    />
                  </S.UtilSvgCanvas>
                </S.StyledLink>
              )}
            </S.UtilList>
          )}
          <S.UtilList
            onMouseEnter={() => setIsFacebookHover(true)}
            onMouseLeave={() => setIsFacebookHover(false)}
          >
            <S.StyledLink
              href={
                router.locale === 'ko'
                  ? 'https://www.facebook.com/LordofHeroesKR'
                  : 'https://www.facebook.com/LordofHeroesGlobal'
              }
              target="_blank"
            >
              <S.UtilSvgCanvas>
                <Image
                  src={
                    isFacebookHover
                      ? `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/facebook_on.svg`
                      : `${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/facebook_off.svg`
                  }
                  alt="Facebook link"
                  fill
                  priority
                />
              </S.UtilSvgCanvas>
            </S.StyledLink>
          </S.UtilList>
        </>
      )}
    </S.UtilBox>
  );
};

export default NavBarUtils;
