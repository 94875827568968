import { motion } from 'framer-motion';
import Link from 'next/link';
import styled from 'styled-components';

export const NavbarContainer = styled.nav`
  height: 4.25rem;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  ${({ theme }) => theme.tablet} {
    height: 6rem;
  }
`;

export const Logo = styled.div`
  height: 100%;
  width: 60px;
  position: relative;
  cursor: pointer;
`;

export const ContentsContainer = styled.div`
  margin-left: 6rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.tablet} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 0;
    position: relative;
  }
`;

export const MenuContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 3rem;
  height: auto;
`;

export const MenuItem = styled(motion.li)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const NavbarLink = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  font-size: 1.3rem;
  position: relative;
  padding: 0.3rem 1rem;
  display: flex;
  width: auto;
  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0.25rem;
    background: black;
    transition: all 0.2s ease-out;
  }
  :hover::after {
    width: 80%;
  }
  :hover {
    color: black;
  }
  :visited {
    color: ${({ selected, theme }) =>
      selected ? 'black' : theme.textColor.disabledColor};
  }
  color: ${({ selected, theme }) =>
    selected ? 'black' : theme.textColor.disabledColor};
  ${({ theme }) => theme.tablet} {
    font-size: 3rem;
  }
`;
